<template>
    <div class="production-service main-wrapper">
        <div class="custom-title">{{ $store.getters.getLanguage === '' ? '核心原料板块' : 'Core Raw Materials Segment'}}</div>
        <div class="production-wrapper">
            <img v-for="(img, idx) in wrapperList" :key="idx" :src="img.imgUrl" @click="handleTop(idx)"/>
        </div>
        <img v-for="(img, index) in cardList" 
            :id="`card-${index}`" 
            :key="`card-${index}`" 
            :src="img.imgUrl"
            @click="handleRoute(index)"/>
    </div>
</template>

<script>
import { getCosUrl } from "../utils";
export default {
  name: "production-service",
  data() {
    return {
        wrapperList: [
            { imgKey: "/production/wrapper/1.webp", imgUrl: "" },
            { imgKey: "/production/wrapper/2.webp", imgUrl: "" },
            { imgKey: "/production/wrapper/3.webp", imgUrl: "" },
            { imgKey: "/production/wrapper/4.webp", imgUrl: "" }
        ],
        cardList: [
            { imgKey: "/production/1.webp", imgUrl: "" },
            { imgKey: "/production/2.webp", imgUrl: "" },
            { imgKey: "/production/3.webp", imgUrl: "" },
            { imgKey: "/production/4.webp", imgUrl: "" },
            { imgKey: "/production/5.webp", imgUrl: "" }
        ]
    };
  },
  watch: {
        "$store.state.device": {
            handler(newVal, oldVal){
                if(newVal != oldVal){
                    this.initWrapperList();
                    this.initCardList();
                }
            },
            deep: true
        },
        "$store.state.language": {
            handler(newVal, oldVal){
                if(newVal != oldVal){
                    this.initWrapperList();
                    this.initCardList();
                }
            },
            deep: true
        }
  },
  mounted() {
    this.initWrapperList();
    this.initCardList();
  },
  methods: {
    initWrapperList(){
        const imgList = getCosUrl(this.wrapperList.map(item => { return item.imgKey }));
        this.wrapperList.forEach((element, index) => {
            element.imgUrl = imgList[index];
        })
    },
    initCardList(){
        const imgList = getCosUrl(this.cardList.map(item => { return item.imgKey }));
        this.cardList.forEach((element, index) => {
            element.imgUrl = imgList[index];
        })
    },
    handleRoute(index) {
        if(index === 4){
            this.$router.push({
                name: "KeyIngredients",
                params: {
                    isJumped: true,
                },
            });
        } else{
            return
        }
    },
    handleTop(index) {
        document.querySelector(`#card-${index}`).scrollIntoView({
            behavior: "smooth",
        });
    },
  },
};
</script>

<style lang="less">
div.production-service {
    flex-direction: column;
    align-items: center;
    display: flex;
    div.custom-title {
        @media screen and (min-device-width: 1200px) {
            margin: 8rem 0;
        }
        @media screen and (max-device-width: 1199px) {
            margin: 8vw 0 5.3333vw 0;
            font-size: 5.3333vw;
        }
    }
    div.production-wrapper {
        justify-content: space-between;
        display: flex;
        img {
            cursor: pointer;
        }
        @media screen and (min-device-width: 1200px){
            margin-bottom: 12rem;
            width: 120rem;
            img{
                width: 23.2rem!important;
            }
            
        }
        @media screen and (max-device-width: 1199px){
            margin-bottom: 8vw;
            width: 88vw;
            img{
                width: 19.7333vw!important;
            }
        }
    }
}
</style>
